.office-album {
  .head {
      margin-top: 3rem;
      margin-bottom: 3rem;
    }

  img {
    display: block;
    max-width: 100%;
    height: 280px;
    object-fit: cover;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: -moz-crisp-edges;
    image-rendering: crisp-edges;
    margin: 2px;
    -webkit-filter: brightness(80%);
    filter: brightness(80%);
  }

  .swiper-1 {
    overflow: hidden;

    &-button-next::after,
    &-button-prev::after {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.75rem;
      font-weight: 800;
      padding: 1rem;
      width: 2rem;
      height: 2rem;
      opacity: 0.75;
      border-radius: 50%;
      color: hsl(206, 5%, 100%);
      background: hsl(210, 20%, 6%);
      overflow: hidden;
    }
  }

}