//
// Typography
//

// Override the default Bootstrap base font family
// 
// Roboto Slab is being used below and is brought in
// via Google fonts in the head of src/pug/index.pug.
// The fonts listed afterwards are fallbacks.
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,300,400,500,600,700,900');


$montserrat: 'Montserrat', sans-serif;

$font-family-base: "Roboto Slab",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";

$headings-font-family: "Montserrat",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";

$subheadings-font-family: $font-family-base;
$headings-font-weight: 700;
$headings-letter-spacing: 0.0625em;
$line-height-lg: 1.75;