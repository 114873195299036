.footer-1 {
    background-color: rgb(52, 52, 62);

    .top {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

        .logo-container {
            padding-right: 1rem;
    
            .logo-box {
                margin-bottom: 2rem;
    
                .logo {
                    width: 200px;
                    height: 60px;
                }
            }
    
            .logo-description {
                color: white;
            }
        }

    .quick-links-container {
        padding-right: 1rem;

        .heading {
            margin-bottom: 1.5rem;
            color: white;
        }

        .quick-links {
            text-decoration: none !important;


            li {
                padding: 3px 0;
                -webkit-transition: .5s all ease;
                -moz-transition: .5s all ease;
                transition: .5s all ease;

                &:hover {
                    padding: 3px 0;
                    margin-left: 8px;
                    font-weight: 400;
                    color: $primary-color;

                }

                a {

                    color: white;
                    &:hover {
                        color: $primary-color;
                    }
                }
            }

            li a i {
                margin-right: 5px;

                &:hover a i {
                    font-weight: 100;

                }
            }

        }
    }


        .social-link-container {
            .heading {
                
                color: white;
            }
    
            .social-links {
                li {
                    padding: 10px 0;
    
                    &:hover a i {
                        font-size: 30px;
                        margin-top: -10px;
                    }
    
                    a i {
                        margin-right: 5px;
                        font-size: 25px;
                        -webkit-transition: .5s all ease;
                        -moz-transition: .5s all ease;
                        transition: .5s all ease;
                    }
    
                    a:hover {
                        color: $primary-color;
                    }
    
                }
            }
        }

                .bottom {
                    border-top: 1px solid grey;
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                    background-color: rgb(19, 19, 28);
        
                    .copyright {
                        margin: 0;
                        color: white;
                        text-align: center;
                    }
                }
}