.landing-page .content .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 140px;
    min-height: calc(100vh - 80px);
}

@media (max-width: 767px) {
    .landing-page .content .container {
        gap: 0;
        min-height: calc(100vh - 101px);
        justify-content: center;
        flex-direction: column-reverse;
    }
}

@media (max-width: 767px) {
    .landing-page .content .info {
        text-align: center;
        margin-bottom: 15px
    }
}

.landing-page .content .info h1 {
    color: #5d5d5d;
    font-size: 44px;
}

.landing-page .content .info p {
    margin: 0;
    line-height: 1.6;
    font-size: 15px;
    color: #5d5d5d;
}

.landing-page .content .info button {
    border: 0;
    border-radius: 20px;
    padding: 12px 30px;
    margin-top: 30px;
    cursor: pointer;
    color: #FFF;
    background-color: #6c63ff;
}

.landing-page .content .image img {
    max-width: 100%;
}

.counter {
    animation-duration: 1s;
    animation-delay: 0s;
  }