.business-reputation {
   
    .box {
        border-radius: 1rem;
        background: linear-gradient(180deg,#fbad1952, #00000008);
        padding: 1.5rem 0.5rem 1.5rem 0.5rem;



.counter {
    animation-duration: 1s;
    animation-delay: 0s;
  }

    }


}