.about-us {
    margin-top: 3rem;
    margin-bottom: 3rem;


    .text-container {
        margin: auto;
    }
    .img-container {
        margin: auto;
    }

    .banner {
        width: 100%;

       
        @media (max-width:767px) {
            display: none;
            &.small {
                filter: brightness(95%);
                display: inline;
            }

        }
        @media (min-width:768px) {
            &.small {
                display: none;
            }
            display: inline;
            filter: brightness(95%);
        }
    }

    .heading {
        text-transform: capitalize;
        letter-spacing: 0.8px;
        font-family: "Roboto", sans-serif;
        font-weight: 900;
        //   font-size: clamp(3.4375rem, 3.25rem + 0.75vw, 4rem);
        background-color: #005baa;
        background-image: linear-gradient(45deg, $primary-color, #000000);
        background-size: 100%;
        background-repeat: repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
        hr {
            display: block;
            background: $primary-color;
            height: 0.25rem;
            width: 6.25rem;
            border: none;
            margin: 1.125rem 0 1.875rem 0;
        }
    }

    .paragraph {
        margin-top: 2rem;
        margin-bottom: 2rem;
        p {
            letter-spacing: 12;
            line-height: 2;
        }

    }
}