.contact-1 {
    .content .info {
        margin-top: 30px;
    }
    .head {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
    .box {
        margin-top: 20px;

    }

    .content .info i {
        font-size: 30px;
        padding: 0;
        margin: 0;
        color: #02434b;
        margin-right: 20px;
        text-align: center;
        width: 20px;
    }

    .content .info h4 {
        font-size: 13px;
        line-height: 1.4;
    }

    .content .info h4 span {
        font-size: 13px;
        font-weight: 300;
        color: #999999;
    }

    .map-box {
        margin-bottom: 4rem;
        width: 100%;
        height: 400px;

        @media (max-width:767px) {
            width: 100%;
            height: 250px;
        }
    }
}