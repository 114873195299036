//
// Colors
//

// Override Bootstrap color variables

$yellow: #ffc800;

// Override Bootstrap state colors variables

$primary: $yellow;

// Override Bootstrap contrast ratio

$min-contrast-ratio: 1.5;

//Colors

$primary-color: #FBAD19;
$secondary-color: #1967FB;
$text-dark: #000000;
$md: 480;

$text: #ffffff;
$background: #0000;
$background-light: #2e2e2e;