.brands {
    margin-top: 3rem;
    .heading {
        text-align: center;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    .box {
        margin: auto;
        margin-top: 20px;
    }

    .brand-row {
        text-align: center;
        div {
            display: inline-block;
        }
    
    }

    .circle{
        width: 200px;
        height: 200px;
        border: 2px solid $primary-color;
        border-radius: 50%;
        overflow: hidden;
        @media (max-width:767px) {
            width:150px;
            height: 150px;
        }
        
            @media (max-width:636px) {
                width:120px;
            height: 120px;
            }
            @media (max-width:539px) {
                width:80px;
            height: 80px;
            }
     }
     .circle img{
        width: 100%;
        height: 100%;
        object-fit: contain;
     }
}