.key-function {
    background-color: rgb(222, 222, 222);
    margin-top:2rem ;
    margin-bottom:2rem ;
    padding-top:2rem ;
    padding-bottom:2rem ;

    .key-container {
        .box {
            text-align: center;
                .icon-box {      
                        margin-bottom: 2rem;
                    i {
                        color: $primary-color;
                        font-size: 2rem;
                    }
                }
                .box-content {
                    font-weight: 500;
                    font-size: 1.5rem;

                }
            }
            @media (max-width:767px) {
            .box {
                text-align: center;
                    .icon-box {
                        margin-bottom: 1rem;
                        i {
                            color: $primary-color;
                            font-size: 1.5rem;
                        }
                    }
                    .box-content {
                        font-weight: 500;
                        font-size: 1rem;
    
                    }
                }
        }
        @media (max-width:575px) {
            .box {
                text-align: center;
                    .icon-box {
                        margin-bottom: 0.5rem;
                        i {
                            color: $primary-color;
                            font-size: 1rem;
                        }
                    }
                    .box-content {
                        font-weight: 500;
                        font-size: 0.8rem;
    
                    }
                }
        }
    }


}