// font stuff




// body {
//     background: #dce1df;
//     color: #4f585e;
//     font-family: 'Source Sans Pro', sans-serif;
// }





.product-container {
    margin: auto auto;
    text-align: center;
    text-rendering: optimizeLegibility;
}

.product-card {
    background: #ffffff;
    display: inline-block;
    margin: 8px;
    max-width: 300px;
    perspective: 1000;
    position: relative;
    text-align: left;
    transition: all 0.3s 0s ease-in;
    width: 300px;
    z-index: 1;

    .product-btn {
        background: #0096a0;
        border-radius: 4px;
        box-shadow: 0 2px 0px 0 rgba(0, 0, 0, 0.25);
        color: #ffffff;
        display: inline-block;
        padding: 6px 30px 8px;
        position: relative;
        text-decoration: none;
        transition: all 0.1s 0s ease-out;

        &:hover {
            background: lighten(#0096a0, 2.5);
            box-shadow: 0px 8px 2px 0 rgba(0, 0, 0, 0.075);
            transform: translateY(-2px);
            transition: all 0.25s 0s ease-out;
        }

        &:active {
            background: darken(#0096a0, 2.5);
            box-shadow: 0 1px 0px 0 rgba(255, 255, 255, 0.25);
            transform: translate3d(0, 1px, 0);
            transition: all 0.025s 0s ease-out;
        }
    }

    .image {
        max-width: 300px;
    }

    .image-holder {
        background: rgba(0, 0, 0, 0.1);
        height: 0;
        padding-bottom: 75%;
    }

    .title {
        background: #ffffff;
        padding: 6px 15px 10px;
        position: relative;
        z-index: 0;

        .flap-info {
            border-radius: 32px;
            height: 32px;
            padding: 0;
            position: absolute;
            right: 15px;
            top: 10px;
            width: 32px;

            span {
                background: #ffffff;
                display: block;
                height: 2px;
                position: absolute;
                top: 16px;
                transition: all 0.15s 0s ease-out;
                width: 12px;
            }

            span.left {
                right: 14px;
                transform: rotate(45deg);
            }

            span.right {
                left: 14px;
                transform: rotate(-45deg);
            }
        }

        h2 {
            font-size: 1.2rem;
            font-weight: 700;
            letter-spacing: -0.05em;
            margin: 0;
            padding: 0;

            small {
                display: block;
                font-size: 0.8rem;
                font-weight: 500;
                letter-spacing: -0.025em;
            }
        }
    }

    .description {
        padding: 0 15px 10px;
        position: relative;
        font-size: 14px;
    }

    .card-actions {
        box-shadow: 0 2px 0px 0 rgba(0, 0, 0, 0.075);
        padding: 10px 15px 20px;
        text-align: center;
    }

    .card-flap {
        background: darken(#ffffff, 15);
        position: absolute;
        width: 100%;
        transform-origin: top;
        transform: rotateX(-90deg);
    }

    .flap1 {
        transition: all 0.3s 0.3s ease-out;
        z-index: -1;
    }

    .flap2 {
        transition: all 0.3s 0s ease-out;
        z-index: -2;
    }

}

.product-container.showing {
    .product-card {
        cursor: pointer;
        opacity: 0.6;
        transform: scale(0.88);
    }
}

.product-container.showing {
    .product-card:hover {
        opacity: 0.94;
        transform: scale(0.92);
    }
}

.product-card.show {
    opacity: 1 !important;
    transform: scale(1) !important;

    .title {
        .flap-info {
            background: #ff6666 !important;

            span {
                top: 15px;
            }

            span.left {
                right: 10px;
            }

            span.right {
                left: 10px;
            }
        }
    }

    .card-flap {
        background: #ffffff;
        transform: rotateX(0deg);
    }

    .flap1 {
        transition: all 0.3s 0s ease-out;
    }

    .flap2 {
        transition: all 0.3s 0.2s ease-out;
    }
}