.start-header {
    opacity: 1;
    transform: translateY(0);
    padding: 10px 0;
    box-shadow: 0 10px 30px 0 rgba(138, 155, 165, 0.15);
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    @media (max-width:539px) {
        padding: 5px 0;
    }
}

.start-header.scroll-on {
    box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
    padding: 10px 0;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.start-header.scroll-on .navbar-brand img {
    height: 24px;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.navigation-wrap {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.navbar {
    padding: 0;
}

.navbar-brand img {
    height: 50px;
    width: auto;
    display: block;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;

}

.navbar-toggler {
    float: right;
    border: none;
    padding-right: 0;
}

.navbar-toggler:active,
.navbar-toggler:focus {
    outline: none;
}

.navbar-light .navbar-toggler-icon {
    width: 24px;
    height: 17px;
    background-image: none;
    position: relative;
    border-bottom: 1px solid #000;
    transition: all 300ms linear;
}

.navbar-light .navbar-toggler-icon:after,
.navbar-light .navbar-toggler-icon:before {
    width: 24px;
    position: absolute;
    height: 1px;
    background-color: #000;
    top: 0;
    left: 0;
    content: '';
    z-index: 2;
    transition: all 300ms linear;
}

.navbar-light .navbar-toggler-icon:after {
    top: 8px;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
    transform: rotate(45deg);
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
    transform: translateY(8px) rotate(-45deg);
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
    border-color: transparent;
}

.nav-link {
    color: #212121 !important;
    font-weight: 500;
    transition: all 200ms linear;
}

.nav-item:hover .nav-link {
    color: #8167a9 !important;
}

.nav-item.active .nav-link {
    color: #777 !important;
}

.nav-link {
    position: relative;
    padding: 5px 0 !important;
    display: inline-block;
}

.nav-item:after {
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 2px;
    content: '';
    background-color: #8167a9;
    opacity: 0;
    transition: all 200ms linear;
}

.nav-item:hover:after {
    bottom: 0;
    opacity: 1;
}

.nav-item.active:hover:after {
    opacity: 0;
}

.nav-item {
    position: relative;
    transition: all 200ms linear;
}