* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    scrollbar-color: rgb(210, 210, 210) rgb(46, 54, 69) !important;
    scrollbar-width: thin !important;
    scroll-behavior: smooth;
}

.web-box {
    padding-top: 6rem;
}

.boundary {
    padding: 5rem 3rem 1rem 3rem;
}

.horizontal-border {
    padding-left: 3rem;
    padding-right: 3rem;

    @media (max-width:767px) {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

.heading {
    font-size: 1.5rem;
    font-weight: 600;

    @media (max-width:767px) {
        font-size: 1.2rem;
    }
}

.title {
    font-size: 1.25rem;
    font-weight: 500;

    @media (max-width:767px) {
        font-size: 1rem;
    }
}

.description {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;

    @media (max-width:767px) {
        font-size: 0.8rem;
    }
}

.page-section {
    padding: 6rem 0;

    h2.section-heading {
        font-size: 2.5rem;
        margin-top: 0;
        margin-bottom: 1rem;
    }

    h3.section-subheading {
        font-size: 1rem;
        font-weight: 400;
        font-style: italic;
        font-family: $subheadings-font-family;
        margin-bottom: 4rem;
    }
}

.rounded-circle {
    border-radius: 50% !important;
}

.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
	margin-top:16px;
}