
@import "~bootstrap/scss/bootstrap";
@import "./variables/colors";
@import "./variables/typography";
@import "_mixins";
@import "_global";
@import "./section/_header";
@import "./section/_landing1";
@import "./section/_landing";
@import "./section/_aboutus";
@import "./section/_brands";
@import "./section/_business-reputation";
@import "./section/timeline";
@import "./section/products";
@import "./section/officealbum";
@import "./section/_keyfuntion";
@import "./section/_contact";
@import "./section/_footer1";

