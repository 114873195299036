  .landing-1 {
      position: relative;
      width: calc(min(90rem, 90%));
      margin: 0 auto;
      min-height: 100vh;
      column-gap: 3rem;
      padding-block: 20vh, 3rem;

      .bg {
          position: fixed;
          top: -4rem;
          left: -12rem;
          z-index: -1;
          opacity: 0;
      }

      .bg2 {
          position: fixed;
          bottom: -2rem;
          right: -3rem;
          z-index: -1;
          width: 9.375rem;
          opacity: 0;
      }

      &>div span {
          text-transform: uppercase;
          letter-spacing: 1.5px;
          font-size: 1rem;
          color: #717171;
      }

      &>div h1 {
          text-transform: capitalize;
          letter-spacing: 0.8px;
          font-family: "Roboto", sans-serif;
          font-weight: 900;
          //   font-size: clamp(3.4375rem, 3.25rem + 0.75vw, 4rem);
          background-color: #005baa;
          background-image: linear-gradient(45deg, #005baa, #000000);
          background-size: 100%;
          background-repeat: repeat;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          -moz-background-clip: text;
          -moz-text-fill-color: transparent;
      }

      &>div hr {
          display: block;
          background: #005baa;
          height: 0.25rem;
          width: 6.25rem;
          border: none;
          margin: 1.125rem 0 1.875rem 0;
      }

      &>div p {
          line-height: 1.6;
      }

      & a {
          display: inline-block;
          text-decoration: none;
          text-transform: uppercase;
          color: #717171;
          font-weight: 500;
          background: #fff;
          border-radius: 3.125rem;
          transition: 0.3s ease-in-out;
      }

      &>div>a {
          border: 2px solid #c2c2c2;
          margin-top: 2.188rem;
          padding: 0.625rem 1.875rem;
      }

      &>div>a:hover {
          border: 0.125rem solid #005baa;
          color: #005baa;
      }

      .swiper {
          width: 100%;
          padding-top: 3.125rem;
      }

      .swiper-pagination-bullet,
      .swiper-pagination-bullet-active {
          background: #fff;
      }

      .swiper-pagination {
          bottom: 1.25rem !important;
      }

      .swiper-slide {
          width: 18.75rem;
          height: 28.125rem;
          display: flex;
          flex-direction: column;
          justify-content: end;
          align-items: self-start;
      }

      .swiper-slide h2 {
          color: #fff;
          font-family: "Roboto", sans-serif;
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 1.4;
          margin-bottom: 0.625rem;
          padding: 0 0 0 1.563rem;
          text-transform: uppercase;
      }

      .swiper-slide p {
          color: #dadada;
          font-family: "Roboto", sans-serif;
          font-weight: 300;
          padding: 0 1.563rem;
          line-height: 1.6;
          font-size: 0.75rem;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
      }

      .swiper-slide a {
          margin: 1.25rem 1.563rem 3.438rem 1.563rem;
          padding: 0.438em 1.875rem;
          font-size: 0.9rem;
      }

      .swiper-slide a:hover {
          color: #005baa;
      }

      .swiper-slide div {
          display: none;
          opacity: 0;
          padding-bottom: 0.625rem;
      }

      .swiper-slide-active div {
          display: block;
          opacity: 1;
      }

      .swiper-slide--one {
          background: linear-gradient(to top, #0f2027, #203a4300, #2c536400),
              url("https://images.unsplash.com/photo-1628944682084-831f35256163?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80") no-repeat 50% 50% / cover;
      }

      .swiper-slide--two {
          background: linear-gradient(to top, #0f2027, #203a4300, #2c536400),
              url("https://images.unsplash.com/photo-1515309025403-4b0184873cef?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=735&q=80") no-repeat 50% 50% / cover;
      }

      .swiper-slide--three {
          background: linear-gradient(to top, #0f2027, #203a4300, #2c536400),
              url("https://images.unsplash.com/photo-1545671913-b89ac1b4ac10?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80") no-repeat 50% 50% / cover;
      }

      .swiper-slide--four {
          background: linear-gradient(to top, #0f2027, #203a4300, #2c536400),
              url("https://images.unsplash.com/photo-1598977123118-4e30ba3c4f5b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80") no-repeat 50% 50% / cover;
      }

      .swiper-slide--five {
          background: linear-gradient(to top, #0f2027, #203a4300, #2c536400),
              url("https://images.unsplash.com/photo-1570481662006-a3a1374699e8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=765&q=80") no-repeat 50% 50% / cover;
      }

      .swiper-3d .swiper-slide-shadow-left,
      .swiper-3d .swiper-slide-shadow-right {
          background-image: none;
      }

      @media screen and (min-width: 48rem) {

          display: flex;
          align-items: center;

          .bg,
          .bg2 {
              opacity: 0.1;
          }
      }

      @media screen and (min-width: 93.75rem) {
          .swiper {
              width: 85%;
          }
      }
  }